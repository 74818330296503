import * as React from "react";
import * as dateFns from "date-fns";
import * as apiClient from "@src/apiClient";
import {LeaderboardEntry} from "@src/Models";
import {currencyFormatter, Header} from "@src/App";

const Leaderboard = () => {
	const [view, setView] = React.useState<string>("month");
	const [baseDate, setBaseDate] = React.useState<Date>(new Date());
	const [data, setData] = React.useState<LeaderboardEntry[]>(null);
	let endDate: Date;
	if(view == "month") {
		endDate = dateFns.addMonths(baseDate, 1);
	} else if(view == "year") {
		endDate = dateFns.addYears(baseDate, 1);
	}
	React.useEffect(() => {
		if(view == "month") {
			setBaseDate(dateFns.addHours(dateFns.startOfMonth(dateFns.addHours(new Date(), -9)), 9));
		} else if(view == "year") {
			setBaseDate(dateFns.addHours(dateFns.startOfYear(dateFns.addHours(new Date(), -9)), 9));
		}
	}, [view]);
	React.useEffect(() => {
		setData(null);
		apiClient.getLeaderboard(baseDate, endDate).then(setData);
	}, [view, baseDate]);
	function paginate(step: number) {
		if(view == "month") {
			setBaseDate(dateFns.addMonths(baseDate, step));
		} else if(view == "year") {
			setBaseDate(dateFns.addYears(baseDate, step));
		}
	}
	return <><Header /><main className="sales">
	<div style={{display: "flex"}}>
	<button onClick={() => paginate(-1)}>Previous</button>
	<button onClick={() => paginate(1)}>Next</button>
	<span className="dateTitle">
	{view == "month" && dateFns.format(baseDate, "yyyy/MM")}
	{view == "year" && dateFns.format(baseDate, "yyyy")}
	</span>
	<button onClick={() => setView("month")}>Month</button>
	<button onClick={() => setView("year")}>Year</button>
	</div>
	{data == null || <>
	<table>
	<thead><tr>
	<th>Name</th>
	<th>Score</th>
	</tr></thead>
	<tbody>
	{data.map(item => <tr key={item.user.id}>
	<td>{item.user.name}</td>
	<td className="amount">{currencyFormatter.format(item.total)}</td>
	</tr>)}
	</tbody>
	</table></>}
	</main></>;
}

export default Leaderboard;