import {combineReducers} from "redux";
import {Transaction} from "@src/Models";
import {createStore} from "redux";

function transactionReducer(state: Transaction[] = [], action: any): Transaction[] {
	if(action.type == "setTransactions")
		return action.transactions;
	if(action.type == "addTransactions")
		return [...state, ...action.transactions];
	return state;
}

function loggedInReducer(state: boolean = false, action: any): boolean {
	if(action.type == "login") return true;
	if(action.type == "logout") {
		localStorage.removeItem("user");
		return false;	
	}
	return state;
}

export const appReducer = combineReducers({
	transactions: transactionReducer,
	loggedIn: loggedInReducer
})

export const store = createStore(appReducer);

export type RootState = ReturnType<typeof appReducer>;