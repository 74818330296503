import * as React from "react";
import * as dateFns from "date-fns";
import * as apiClient from "@src/apiClient";
import {UserInfo} from "@src/Models";
import {Header} from "@src/App";

export function Customers() {
	const [customerData, setCustomerData] = React.useState<UserInfo[]>(null);
	React.useEffect(() => {
		apiClient.getCustomers().then(setCustomerData);
	}, []);
	if(customerData != null)
	customerData.forEach(data => data.lastTransaction = dateFns.max([data.lastExpense, data.lastCharge]));
	return <><Header /><main className="customers">
	{customerData == null || <table>
	<thead><tr>
	<th>Name</th>
	<th>Last payment</th>
	<th>Last charge</th>
	</tr>
	</thead>
	<tbody>
	{customerData.filter(x => x.lastExpense != null || x.lastCharge != null).map(item => <tr key={item.id} className={item.lastTransaction != null && dateFns.differenceInDays(new Date(), item.lastTransaction) >= 45 ? "alert" : ""}>
	<td>{item.name}</td>
	<td className="date">{item.lastExpense == null ? "-" : dateFns.format(item.lastExpense, "yyyy/MM/dd HH:mm:ss")}</td>
	<td className="date">{item.lastCharge == null ? "-" : dateFns.format(item.lastCharge, "yyyy/MM/dd HH:mm:ss")}</td>
	</tr>)}
	</tbody>
	</table>}
	</main></>;
}
