import * as React from "react";
import * as apiClient from "@src/apiClient";
import "@src/app.scss";
import {useSelector, useDispatch} from "react-redux";
import {RootState} from "@src/Reducers";
import {setTransactions, addTransactions, login, logout} from "@src/Actions";
import {Transaction} from "@src/Models";
import * as dateFns from "date-fns";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory } from "react-router-dom";
import {Sales} from "@src/Sales";
import {Balances} from "@src/Balances";
import {Customers} from "@src/Customers";
import Leaderboard from "@src/Leaderboard";
import CounterWidget from "@src/CounterWidget";

export const currencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'JPY',
});

function DayStats() {
	const transactions: Transaction[] = useSelector<RootState, Transaction[]>(state => state.transactions);
	return <><div className="stat">
	<h1>Sales</h1>
	<span className="amount sales">{currencyFormatter.format(transactions.reduce((currentValue, transaction: Transaction) => transaction.cash < 0 ? currentValue - transaction.cash : currentValue, 0))}</span>
	</div><div className="stat">
	<h1>Points</h1>
	<span className="amount points">{currencyFormatter.format(transactions.reduce((currentValue, transaction: Transaction) => transaction.points < 0 ? currentValue - transaction.points : currentValue, 0))}</span>
	</div><div className="stat">
	<h1>Charge</h1>
	<span className="amount charges">{currencyFormatter.format(transactions.reduce((currentValue, transaction: Transaction) => transaction.cash > 0 ? currentValue + transaction.cash : currentValue, 0))}</span>
	</div><div className="stat">
	<h1>Awards</h1>
	<span className="amount awards">{currencyFormatter.format(transactions.reduce((currentValue, transaction: Transaction) => transaction.points > 0 ? currentValue + transaction.points : currentValue, 0))}</span>
	</div></>;
}

function Transaction(props: {
	transaction: Transaction
}) {
	return <li className={props.transaction.description}>
		<span className="date">{dateFns.format(props.transaction.date, "HH:mm:ss")}</span>
		<span className="action">{props.transaction.description}</span>
		<span className="amount">{currencyFormatter.format(Math.abs(props.transaction.cash + props.transaction.points))}</span>
		<span className="user">{props.transaction.user.name}</span>
	</li>;
}

function TransactionList() {
	const transactions: Transaction[] = useSelector<RootState, Transaction[]>(state => state.transactions);
	return <ol className="transaction-list">
		{transactions.map(transaction => <Transaction key={transaction.id} transaction={transaction} />)}
	</ol>;
}

function Menu() {
	const loggedIn = useSelector<RootState, boolean | null>(state => state.loggedIn);
	const [toggled, setToggled] = React.useState(false);
	if(loggedIn == null) return null;
	return <nav>
	<div className={"hamburger" + (toggled ? " toggled": "")} onClick={() => setToggled(!toggled)}><span></span><span></span><span></span></div>
	<ul onClick={() => setToggled(false)}>
	<li><Link to="/balances">Balances</Link></li>
	<li><Link to="/sales">Past sales</Link></li>
	<li><Link to="/customers">Customers</Link></li>
	<li><Link to="/leaderboard">Leaderboard</Link></li>
	</ul>
	</nav>;
}

const BarcodeReader: React.FC<{
	onScan: (code: string) => void;
}> = ({onScan}) => {
	const data = React.useRef<string>("");
	function handleKeyDown(e: KeyboardEvent) {
		if(e.key == "Enter") {
			onScan(data.current);
			data.current = "";
		} else if(e.key == "Backspace") {
			data.current = "";
		} else if(e.key.length == 1 || data.current.length < 32) data.current = data.current + e.key;
	}
	React.useEffect(() => {
		window.document.addEventListener('keydown', handleKeyDown);
		return () => {window.document.removeEventListener('keydown', handleKeyDown)};
	}, [])
	return null;
}

export function Header() {
	return <header><Link to="/"><h1>Osaka Salon Pay</h1></Link><Menu /></header>;
}

function Main() {
	const [result, setResult] = React.useState<string>(null);
	const [keyboardVisible, setKeyboardVisible] = React.useState<boolean>(false);
	const [inputText, setInputText] = React.useState<string>("");
	const dispatch = useDispatch();
	const paySound = new Audio('/jean2.mp3');
	const otsumamiSound = new Audio('/otsumami.mp3');
	let baseDate = dateFns.addHours(new Date(), -9);
	let date = dateFns.addHours(dateFns.startOfDay(baseDate), 9);
	React.useEffect(() => {
		if(result != null && result != "")
			apiClient.validateCode(result)
			.then(transactions => {
				dispatch(addTransactions(transactions));
				for(let transaction of transactions) {
					if(transaction.description == "otsumami") {
						otsumamiSound.play();
						break;
					}
					if(transaction.description == "payment") {
						paySound.play();
						break;
					}
				}
			})
			.catch(() => toast("Could not process QR code"));
	}, [result]);
	React.useEffect(() => {
		apiClient.getTransactions(date, dateFns.addDays(date, 1)).then(transactions => dispatch(setTransactions(transactions)));
	}, []);
	function onScan(data: string) {
		if(data != null && !keyboardVisible) {
			if(data.startsWith("Battery")) {
				let battery = data.substr(8).trim();		
				toast("Battery level: " + battery);
			} else {
				setInputText("");
				setResult(data);	
			}
		}
	}
	function inputTextChange(e: React.ChangeEvent<HTMLInputElement>) {
		if(e.target.value.length == 32) {
			setInputText("");
			setResult(e.target.value);
		} else setInputText(e.target.value);
	}
	return <>
	<BarcodeReader onScan={onScan} />
	<Header />
	<div className="main-screen">
	<main>
	<div style={{display: "flex"}}>
	<h1 style={{flexGrow: 1}}>Register for {dateFns.format(baseDate, "yyyy/MM/dd")}</h1>
	<img src="ico/keyboard.svg" alt="Toggle keyboard" onClick={() => setKeyboardVisible(!keyboardVisible)} style={{height: "1em"}} />
	</div>
	<TransactionList />
	</main>
	<aside>
	<DayStats />
	</aside>
	</div>
	{keyboardVisible && <footer style={{display: "flex"}}>
	<input type="text" style={{flexGrow: 1}} value={inputText} onChange={inputTextChange} />
	<img src="ico/clear.svg" alt="Clear" onClick={() => setInputText("")} style={{height: "1em"}} />
	</footer>}
	</>;
}
function Login() {
	return <div className="login">
		<h1>Osaka Salon Cashier</h1>
		<p>Login with</p>
		<Link to="/login?authclient=facebook"><img src="/ico/facebook.svg" alt="Facebook" /></Link>
	</div>;
}

function SSO() {
	let urlParams = new URLSearchParams(window.location.search);
	if (urlParams.has("authclient"))
		apiClient.getAuthUrl(urlParams.get("authclient"),
		new URL("../authenticate?authclient=" + urlParams.get("authclient"), document.baseURI).href)
		.then(url => window.location.href = url);
	return null;
}

function Authenticate() {
	const dispatch = useDispatch();
	const history = useHistory();
	apiClient.sendAuthCode()
		.then(token => {
			localStorage.setItem("user", token);
			return apiClient.getProfile();
		})
		.then(profile => {
			if(profile.staff)
				dispatch(login());
			else {
				toast(<><p>User not authorized</p></>);
				dispatch(logout());
			}
			history.push("/");
		})
		.catch(() => {
			toast(<><p>Could not authenticate</p></>);
			dispatch(logout());
			history.push("/");
		});
	return null;
}

export function App() {
	const loggedIn = useSelector<RootState, boolean>(state => state.loggedIn);
	const dispatch = useDispatch();
	React.useEffect(() => {
		if(apiClient.isLoggedIn())
			dispatch(login());
		else
			dispatch(logout());
	}, []);
	return <Router>
		{!loggedIn && <Switch>
			<Route path="/login"><SSO /></Route>
			<Route path="/authenticate"><Authenticate /></Route>
			<Route path="/counter-widget"><CounterWidget /></Route>
			<Route path="/"><Login /></Route>
		</Switch>}
		{loggedIn && <Switch>
			<Route path="/authenticate"><Redirect to="/" /></Route>
			<Route path="/sales"><Sales /></Route>
			<Route path="/balances"><Balances /></Route>
			<Route path="/customers"><Customers /></Route>
			<Route path="/leaderboard"><Leaderboard /></Route>
			<Route path="/counter-widget"><CounterWidget /></Route>
			<Route path="/"><Main /></Route>
		</Switch>}
		<ToastContainer
			position="bottom-center"
			hideProgressBar={true}
			newestOnTop={true}
			draggable={true}
			bodyClassName="toast-body"
		/>	
	</Router>;
}