import * as React from "react";
import * as dateFns from "date-fns";
import * as apiClient from "@src/apiClient";
import {Transaction} from "@src/Models";
import {currencyFormatter, Header} from "@src/App";

export function Sales() {
	const [view, setView] = React.useState<string>("day");
	const [baseDate, setBaseDate] = React.useState<Date>(new Date());
	const [data, setData] = React.useState<Transaction[]>(null);
	let cashSales = 0;
	let pointSales = 0;
	let totalSales = 0;
	let cashCharge = 0;
	let pointCharge = 0;
	let endDate: Date;
	if(view == "day") {
		endDate = dateFns.addDays(baseDate, 1);
	} else if(view == "month") {
		endDate = dateFns.addMonths(baseDate, 1);
	}
	React.useEffect(() => {
		if(view == "day") {
			setBaseDate(dateFns.addHours(dateFns.startOfDay(dateFns.addHours(new Date(), -9)), 9));
		} else if(view == "month") {
			setBaseDate(dateFns.addHours(dateFns.startOfMonth(dateFns.addHours(new Date(), -9)), 9));
		}
	}, [view]);
	React.useEffect(() => {
		setData(null);
		apiClient.getTransactions(baseDate, endDate).then(setData);
	}, [view, baseDate]);
	if(data != null) {
		data.forEach(transaction => {
			if(transaction.cash < 0 && transaction.description == "payment") cashSales += -transaction.cash;
			if(transaction.cash > 0 && transaction.description == "charge") cashCharge += transaction.cash;
			if(transaction.points < 0 && transaction.description == "payment") pointSales += -transaction.points;
			if(transaction.points > 0 && transaction.description == "charge") pointCharge += transaction.points;
		});
		totalSales = cashSales + pointSales;
	}
	function paginate(step: number) {
		if(view == "day") {
			setBaseDate(dateFns.addDays(baseDate, step));
		} else if(view == "month") {
			setBaseDate(dateFns.addMonths(baseDate, step));
		}
	}
	return <><Header /><main className="sales">
	<div style={{display: "flex"}}>
	<button onClick={() => paginate(-1)}>Previous</button>
	<button onClick={() => paginate(1)}>Next</button>
	<span className="dateTitle">
	{view == "day" && dateFns.format(baseDate, "yyyy/MM/dd")}
	{view == "month" && dateFns.format(baseDate, "yyyy/MM")}
	</span>
	<button onClick={() => setView("month")}>Month</button>
	<button onClick={() => setView("day")}>Day</button>
	</div>
	{data == null || <>
	<ul>
	<li>Cash sales: <span className="amount">{currencyFormatter.format(cashSales)}</span></li>
	<li>Point sales: <span className="amount">{currencyFormatter.format(pointSales)}</span></li>
	<li>Total sales: <span className="amount">{currencyFormatter.format(totalSales)}</span></li>
	<li>Cash charge: <span className="amount">{currencyFormatter.format(cashCharge)}</span></li>
	<li>Point charge: <span className="amount">{currencyFormatter.format(pointCharge)}</span></li>
	</ul>
	<table>
	<thead><tr>
	<th>Time / Staff</th>
	<th>Name</th>
	<th>Cash</th>
	<th>Points</th>
	<th>Description</th>
	</tr></thead>
	<tbody>
	{data.map(item => <tr key={item.id}>
	<td><span className="date">{dateFns.format(item.date, "yyyy/MM/dd HH:mm:ss")}</span><br />{item.staff.name}</td>
	<td>{item.user.name}</td>
	<td className="amount">{currencyFormatter.format(item.cash)}</td>
	<td className="amount">{currencyFormatter.format(item.points)}</td>
	<td>{item.description}</td>
	</tr>)}
	</tbody>
	</table></>}
	</main></>;
}
