import * as React from "react";
import * as dateFns from "date-fns";
import * as apiClient from "@src/apiClient";
import {BalanceData} from "@src/Models";
import {currencyFormatter, Header} from "@src/App";

export function Balances() {
	const [date, setDate] = React.useState<Date>(new Date());
	const [balanceData, setBalanceData] = React.useState<any[]>(null);
	const [sortField, setSortField] = React.useState<string>("total");
	let totalPoints = 0;
	let totalCash = 0;
	function setRawBalanceData(rawBalanceData: BalanceData[]) {
		let balanceData = [];
		rawBalanceData.forEach((item, index) => {
			balanceData.push({id: index, ...item, total: item.cash + item.points});
		});
		setBalanceData(balanceData);
	}
	React.useEffect(() => {
		apiClient.getBalances(dateFns.addHours(dateFns.startOfDay(dateFns.addHours(date, -9)), 9+24)).then(setRawBalanceData);
	}, [date]);
	React.useEffect(() => {
		if(balanceData != null)
			setBalanceData(balanceData.sort((a, b) => {
				if(sortField == "name") return a.user.name < b.user.name ? -1 : (a.user.name == b.user.name ? 0 : 1);
				else if (sortField == "cash") return a.cash - b.cash;
				else if (sortField == "points") return a.points - b.points;
				else if (sortField == "total") return (a.points + a.cash) - (b.points + b.cash);
				else return a.id - b.id;
			}));
	}, [sortField]);
	if(balanceData != null)
		balanceData.forEach(item => {
			totalPoints += item.points;
			totalCash += item.cash;
		});
	return <><Header /><main className="balances">
	<label>By the end (9am) of:<input type="date" value={dateFns.format(dateFns.startOfDay(dateFns.addHours(date, -9)), "yyyy-MM-dd")} onChange={e => setDate(e.target.valueAsDate)} /></label>
	{balanceData == null || <table>
	<thead><tr>
	<th onClick={() => setSortField("name")}>Name</th>
	<th onClick={() => setSortField("cash")}>Cash</th>
	<th onClick={() => setSortField("points")}>Points</th>
	<th onClick={() => setSortField("total")}>Total</th>
	</tr><tr>
	<td>Total</td>
	<td className="amount">{currencyFormatter.format(totalCash)}</td>
	<td className="amount">{currencyFormatter.format(totalPoints)}</td>
	<td className="amount">{currencyFormatter.format(totalPoints + totalCash)}</td>
	</tr></thead>
	<tbody>
	{balanceData.map(item => <tr key={item.user.id}>
	<td>{item.user.name}</td>
	<td className="amount">{currencyFormatter.format(item.cash)}</td>
	<td className="amount">{currencyFormatter.format(item.points)}</td>
	<td className="amount">{currencyFormatter.format(item.total)}</td>
	</tr>)}
	</tbody>
	</table>}
	</main></>;
}
