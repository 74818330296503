import {Transaction} from "@src/Models";

export function addTransactions(transactions: Transaction[]) {
	return {
		type: "addTransactions",
		transactions: transactions
	}
}

export function setTransactions(transactions: Transaction[]) {
	return {
		type: "setTransactions",
		transactions: transactions
	}
}

export function login() {
	return { type: "login" };
}

export function logout() {
	return { type: "logout" };
}