import * as Axios from 'axios';
import axios from 'axios';
import {Transaction, BalanceData, UserInfo, LeaderboardEntry} from "@src/Models";
import {store} from "@src/Reducers";
import {logout} from "@src/Actions";

const client: Axios.AxiosInstance = axios.create({
	baseURL: "/api"
});
client.interceptors.request.use(function(config) {
	if (localStorage.getItem("user") !== null && config.method != "OPTIONS") {
		let accessToken: string = localStorage.getItem("user");
		let jwt: { nbf: number, exp: number } = JSON.parse(atob(accessToken.split(".")[1]));
		let now: number = Math.ceil(Date.now() / 1000);
		if (now >= jwt.exp)
			localStorage.removeItem('user');
		else if ((jwt.nbf + (jwt.exp - jwt.nbf) * 0.75) <= now && config.url != "/auth/refresh-token")
			getRefreshToken().then((accessToken: string) => {
				localStorage.setItem('user', accessToken);
			}).catch(() => {
				console.log("Timeout logout");
				localStorage.removeItem('user');
			});
		if (accessToken !== undefined)
			config.headers = { ...config.headers, Authorization: "Bearer " + accessToken }
	}
	return config;
})
client.interceptors.response.use(response => response, error => {
	console.log(error);
	if(error.response.status == 401 || error.response.status == 403) store.dispatch(logout());
	else return Promise.reject(error);
});
export function isLoggedIn(): boolean {
	let accessToken: string = localStorage.getItem("user");
	if(accessToken == null) return false;
	let jwt: { nbf: number, exp: number } = JSON.parse(atob(accessToken.split(".")[1]));
	let now: number = Math.ceil(Date.now() / 1000);
	if(now >= jwt.exp) {
		localStorage.removeItem('user');
		return false;
	}
	return true;
}
export async function getAuthUrl(clientId: string, redirect: string): Promise<string> {
	return client.get("/auth/url", {
		params: {
			client: clientId,
			redirect: redirect
		}
	}).then((response: Axios.AxiosResponse<any>) => {
		return response.data.url;
	});
}
async function getRefreshToken(): Promise<string> {
	return client.get("/auth/refresh-token").then((response: Axios.AxiosResponse<any>) => {
		return response.data.accessToken;
	});
}
export async function sendAuthCode(): Promise<string> {
	return client.get("/auth/authenticate" + window.location.search).then((response: Axios.AxiosResponse<any>) => {
		return response.data.accessToken;
	});
}

export async function validateCode(code: string): Promise<Transaction[]> {
	return client.post("/staff/process", code, {
		transformResponse: [(rawData: string) => {
			let data = JSON.parse(rawData);
			if(Array.isArray(data))
				return data.map(item => {
					return {
						...item,
						date: new Date(item.date)
					};
				});
			return rawData;				
		}]
	}).then((response: Axios.AxiosResponse<Transaction[]>) => {
		return response.data;
	});
}

export async function getTransactions(from: Date, to: Date): Promise<Transaction[]> {
	return client.get("/staff/transactions", {
		params: {
			from: from.toISOString(),
			to: to.toISOString(),
		},
		transformResponse: [(rawData: string) => {
			let data = JSON.parse(rawData);
			if(Array.isArray(data))
				return data.map(item => {
					return {
						...item,
						date: new Date(item.date)
					};
					});
			return rawData;				
		}]
	}).then((response: Axios.AxiosResponse<Transaction[]>) => {
		return response.data;
	});
}

export async function getBalances(at: Date): Promise<BalanceData[]> {
	return client.get("/staff/balances", {
		params: {
			at: at.toISOString(),
		},
	}).then((response: Axios.AxiosResponse<BalanceData[]>) => {
		return response.data;
	});
}

export async function getCustomers(): Promise<UserInfo[]> {
	return client.get("/staff/user-list", {
		transformResponse: [(rawData: string) => {
			let data = JSON.parse(rawData);
			if(Array.isArray(data))
				return data.map(item => {
					return {
						...item,
						lastExpense: item.lastExpense == null ? null : new Date(item.lastExpense),
						lastCharge: item.lastCharge == null ? null : new Date(item.lastCharge)
					};
					});
			return rawData;				
		}]
	}).then((response: Axios.AxiosResponse<UserInfo[]>) => {
		return response.data;
	});
}

export async function getProfile(): Promise<any> {
	return client.get("/auth/me").then((response: Axios.AxiosResponse<any>) => {
		return response.data;
	});
}

export async function getLeaderboard(from: Date, to: Date): Promise<LeaderboardEntry[]> {
	return client.get("/staff/leaderboard", {
		params: {
			from: from.toISOString(),
			to: to.toISOString(),
		},
	}).then((response: Axios.AxiosResponse<LeaderboardEntry[]>) => {
		return response.data;
	});
}