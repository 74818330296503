import * as React from "react";
import * as apiClient from "@src/apiClient";
import { useFilePicker } from 'use-file-picker';
import {LeaderboardEntry} from "@src/Models";
import * as dateFns from "date-fns";

const LoginPrompt = () => {
	const [openFileSelector, { filesContent }] = useFilePicker({ accept: [".key"], multiple: false });
	React.useEffect(() => {
		if(filesContent.length > 0) {
			localStorage.setItem("user", filesContent[0].content.trim());
			location.reload();
		}
	}, [filesContent]);
	return <button onClick={openFileSelector}>Load key</button>;
}
const Widget = () => {
	React.useEffect(() => {
		const handler1 = setTimeout(onTimer, 5000);
		const handler2 = setInterval(onTimer, 5*60*1000);
		return () => {
			clearInterval(handler1);
			clearInterval(handler2);
		};
	}, []);
	const [leaderboard, setLeaderboard] = React.useState<LeaderboardEntry[]>();
	const onTimer = () => {
		const baseDate = dateFns.addHours(dateFns.startOfMonth(dateFns.addHours(new Date(), -9)), 9);
		const endDate = dateFns.addMonths(baseDate, 1);
		apiClient.getLeaderboard(baseDate, endDate).then(setLeaderboard).catch((error) => {
			if(error.response.status == 401 || error.response.status == 403) {
				localStorage.removeItem("user");
				location.reload();
			}
		});
	};
	return <><h1>Leaderboard</h1><ol className="leaderboard">
	{leaderboard && leaderboard.map(entry => <li key={entry.user.id}>{entry.user.name}</li>)}
	</ol></>;
}

const CounterWidget = () => {
	return <main className="counter-widget">
	{apiClient.isLoggedIn() ? <Widget /> : <LoginPrompt />}
	</main>
}

export default CounterWidget;